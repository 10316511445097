<template>
<div>
  <template v-if="loading">
  <div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 opacity-50 hover:opacity-100 transition-opacity duration-100 ease-out">
    <span class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="
      top: 50%;">
    <i class="fas fa-circle-notch fa-spin fa-5x"></i>
  </span>
  </div>
  </template>
  <template v-else>
    <div class="job_box">
    <div class="flex flex-wrap">
        <div class="w-full"><a href="/">&lt; Back</a></div>
    <div class="w-full job-title"><h2 class="">{{ job.title }}</h2></div>
    <hr class="w-full my-3">
      <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 text-gray-600">{{ job.publishedCategory.name }}</div>
      <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 text-right">
    <span class="ml-2 text-xs font-bold px-3 py-1 rounded-full bg-gray-200 text-gray-700">{{ job.address.city }}, {{ job.address.state }}</span>
    <span class="ml-2 text-xs font-bold px-3 py-1 rounded-full bg-gray-200 text-gray-700">{{ job.employmentType }}</span>

      </div>
      <div class="job_description w-full" v-html="job.publicDescription"></div>
      <hr class="w-full my-3">
      <div class="mx-auto relative block w-full my-5"><router-link :to="{ name: 'Apply', params: { job: job.id }}" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"><button>Apply to this Position</button></router-link></div>
    </div>
    </div>
  </template>
</div>
</template>

<style>
#app_createch {
  padding: 10px;
}
.job_description > ol, .job_description > ul {
  list-style-type: disc;
  list-style: circle;
}
.job_description > ul > li {
  margin-left: 30px;
}
.job_box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  opacity: 1;
  margin: 30px;
  padding: 10px;
  border-left: 10px solid #00bcd0;
}
.job_title {
  margin-top: -15px;
}

</style>

<script>
import axios from "axios";

export default {
  name: 'Createch-Bullhorn',
  components: {
  },
  data() {
    return {
      job_id: 0,
      swimlane: process.env.VUE_APP_BULLHORN_SWIMLANE,
      corpid: process.env.VUE_APP_BULLHORN_CORPID,
      loading: true,
      set_location: "",
      signalsUrl: process.env.VUE_APP_SIGNALS_API,
      job: false,
      sending: false
    };
  },
  computed: {

  },
  created() {
    this.job_id = this.$route.params.job;
    this.get_job()
  },
  methods: {
    get_job: function() {
      axios
        .get(`https://public-rest${this.swimlane}.bullhornstaffing.com/rest-services` +
        `/${this.corpid}/query/JobBoardPost?where=(id=${this.job_id})&fields=id,title` +
        `,publishedCategory(id,name),address(city,state,countryName),employmentType,` +
        `dateLastPublished,publicDescription,isOpen,isPublic,isDeleted,publishedZip,salary,salaryUnit`)
        .then(
          response => (this.set_job(response))
        )
    },
    set_job: function(job_json) {
      this.job = job_json.data.data[0];
      this.job.publicDescription = this.job.publicDescription.replace(
        "http://www.createch.com/wp-content/uploads/2017/11/CreatechLOGOsm.png\" style=\"float:left; height:46px; width:152px\"",
        "https://images.squarespace-cdn.com/content/5f651fa1674379687d2fc3c0/1602200519077-KAB4YEXN6JUE34DC5QY9/blk-logo.png\"")
      setTimeout(() => this.loading = false, 500);
    }
  },
}
</script>
<template>
<div>
  <template v-if="loading">
  <div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 opacity-50 hover:opacity-100 transition-opacity duration-100 ease-out">
    <span class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="
      top: 50%;">
    <i class="fas fa-circle-notch fa-spin fa-5x"></i>
  </span>
  </div>
  </template>
  <template v-else>
    <div class="job_box">
    <form class="w-full max-w-lg">
      <h2>Submit your resume</h2>
      Position: {{job.title}}
      <hr class="w-full my-3">
      <input type="hidden" name="job-id" :value="job.id">
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
            First Name
          </label>
          <input name="first_name" required class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="First">
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
            Last Name
          </label>
          <input name="last_name" required class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Last">
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-email">
            Email
          </label>
          <input name="email" required class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email">
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-phone">
            Mobile Phone
          </label>
          <input name="phone" required class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-phone" type="text">
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-phone">
            Resume
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="file"
              name="documents[]"
              accept=".doc,.docx,.pdf,.html,.text,.txt,.rtf,.odt"
          >
          <p class="text-gray-700 text-xs italic"> Accepted Resume types are html,text,txt,pdf,doc,docx,rtf,odt </p>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-2">
          <hr class="w-full my-3">
          <div class="relative block w-full mx-5"><button type="submit" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Apply to this Position</button></div>
      </div>
    </form>
    </div>
  </template>
</div>
</template>

<style>
#app_createch {
  padding: 10px;
}
.job_box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  opacity: 1;
  margin: 30px;
  padding: 10px;
  border-left: 10px solid #00bcd0;
}
.job_title {
  margin-top: -15px;
}

</style>

<script>
import axios from "axios";

export default {
  name: 'Createch-Bullhorn',
  components: {
  },
  data() {
    return {
      job_id: 0,
      swimlane: process.env.VUE_APP_BULLHORN_SWIMLANE,
      corpid: process.env.VUE_APP_BULLHORN_CORPID,
      loading: true,
      set_location: "",
      signalsUrl: process.env.VUE_APP_SIGNALS_API,
      job: false,
      sending: false
    };
  },
  computed: {

  },
  created() {
    this.job_id = this.$route.params.job;
    this.get_job()
  },
  methods: {
    get_job: function() {
      axios
        .get(`https://public-rest${this.swimlane}.bullhornstaffing.com/rest-services` +
        `/${this.corpid}/query/JobBoardPost?where=(id=${this.job_id})&fields=id,title` +
        `,publishedCategory(id,name),address(city,state,countryName),employmentType,` +
        `dateLastPublished,publicDescription,isOpen,isPublic,isDeleted,publishedZip,salary,salaryUnit`)
        .then(
          response => (this.set_job(response))
        )
    },
    set_job: function(job_json) {
      this.job = job_json.data.data[0];
      this.job.publicDescription = this.job.publicDescription.replace(
        "http://www.createch.com/wp-content/uploads/2017/11/CreatechLOGOsm.png\" style=\"float:left; height:46px; width:152px\"",
        "https://images.squarespace-cdn.com/content/5f651fa1674379687d2fc3c0/1602200519077-KAB4YEXN6JUE34DC5QY9/blk-logo.png\"")
      setTimeout(() => this.loading = false, 500);
    }
  },
}
</script>
<template>
<div class="home">
  <div id="search_background">
    <div class="flex flex-wrap">
     <div id="search_box" class="pt-2 relative mx-auto">
        <input v-on:keyup="filter_jobs" v-model="filter" class="border-2 border-gray-400 bg-white h-10 px-5 pr-16 text-sm focus:outline-none"
          type="search" name="search" placeholder="Search">
        <button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
          <svg class="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
            viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
            width="512px" height="512px">
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <template v-if="loading">
<div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 opacity-50 hover:opacity-100 transition-opacity duration-100 ease-out">
  <span class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="
    top: 50%;
">
    <i class="fas fa-circle-notch fa-spin fa-5x"></i>
  </span>
</div>
  </template>
  <template v-else>
    <div v-for="job in jobs_filtered" v-bind:key="job.id">
      <router-link :to="{ name: 'Lead', params: { job: job.id }}">
        <div class="job_box">
        <div class="flex flex-wrap">
          <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 text-gray-600">{{ job.publishedCategory.name }}</div>
          <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 text-right">
        <span class="ml-2 text-xs font-bold px-3 py-1 rounded-full bg-gray-200 text-gray-700">{{ job.address.city }}, {{ job.address.state }}</span>
        <span class="ml-2 text-xs font-bold px-3 py-1 rounded-full bg-gray-200 text-gray-700">{{ job.employmentType }}</span>
          </div>
          <div class="job_title w-full"><h2 class="">{{ job.title }}</h2></div>
          <div class="job_description w-full">{{ job.publicDescription | striphtml }}</div>
        </div>
        </div>
      </router-link>
    </div>
  </template>
  </div>
</template>

<style scoped>
#search_background {
  background-color: black;
  height: 33px;
}
#search_box {
  width: 100%;
  margin-left: 30px;
  margin-right: 30px;
}
#search_box > input {
  width: 100%;
}
#app_createch {
  padding: 10px;
}
.job_box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  opacity: 1;
  margin: 30px;
  padding: 10px;
  border-left: 10px solid #00bcd0;
}
.job_title {
  margin-top: -15px;
}
.job_description {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}
</style>

<script>
// @ is an alias to /src
// import Jobs from '@/components/Jobs.vue'
import axios from "axios";

export default {
  name: 'Createch-Bullhorn',
  components: {
  },
  data() {
    return {
      swimlane: process.env.VUE_APP_BULLHORN_SWIMLANE,
      corpid: process.env.VUE_APP_BULLHORN_CORPID,
      loading: true,
      filter: "",
      signalsUrl: process.env.VUE_APP_SIGNALS_API,
      jobs: [],
      jobs_filtered: [],
      sending: false
    };
  },
  computed: {

  },
  created() {
    this.get_jobs()
  },
  methods: {
    get_jobs: function() {
      axios
        .get(`https://public-rest${this.swimlane}.bullhornstaffing.com/rest-services` +
        `/${this.corpid}/search/JobOrder?start=0&query=(isOpen:1)%20AND%20(isDeleted:0)` +
        '&fields=id,title,publishedCategory(id,name),address(city,state,countryName)' +
        ',employmentType,dateLastPublished,publicDescription,isOpen,isPublic,isDeleted,' +
        'publishedZip,salary,salaryUnit&count=30&sort=-dateLastPublished&showTotalMatched=true')
        .then(
          response => (this.set_jobs(response))
        )
    },
    set_jobs: function(jobs_json) {
      this.jobs = jobs_json;
      this.filter_jobs();
      setTimeout(() => this.loading = false, 500);
    },
    filter_jobs: function() {
      if (this.filter == '') {
        this.jobs_filtered = this.jobs.data.data;
      }
      else {
          this.jobs_filtered = [];
          for (var i = 0; i < this.jobs.data.data.length; i++) {
            var job = this.jobs.data.data[i]
            if(job.title.toLowerCase().includes(this.filter.toLowerCase())) {
              this.jobs_filtered.push(job)
              continue
            }
            if(job.address.city.toLowerCase().includes(this.filter.toLowerCase())) {
              this.jobs_filtered.push(job)
              continue
            }
            if(job.address.state.toLowerCase().includes(this.filter.toLowerCase())) {
              this.jobs_filtered.push(job)
              continue
            }
            if(job.publicDescription.replace(/<\/?[^>]+(>|$)/g, "").toLowerCase().includes(this.filter.toLowerCase())) {
              this.jobs_filtered.push(job)
              continue
            }
          }
      }
    }
  },
  filters: {
    striphtml: function(value) {
      var div = document.createElement("div");
      div.innerHTML = value;
      var text = div.textContent || div.innerText || "";
      return text;
    }
  }
}
</script>
